<template>
  <b-card no-body class="mb-1">
    <b-card-header class="p-1">
      <b-button block v-b-toggle.geral variant="qualquenome"
        ><h4>CADASTROS</h4>
      </b-button>
    </b-card-header>

    <b-card-body>
      <b-row>
        <!-- CARD GERAL-->
        <b-col xl="3" sm="6" class="p-2">
          <b-media no-body class="panel">
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="warning"> 100% </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ cadastros.length }}
              </h4>
              <b-card-text class="font-small-3 mb-0"> TOTAL </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <!-- CARD DESCARTADO-->
        <b-col xl="3" sm="6" class="p-2">
          <b-media no-body class="panel">
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="danger">
                {{ calcTotalPercent(0) }}%
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ setGeral(0) }}
              </h4>
              <b-card-text class="font-small-3 mb-0"> DESCARTADO </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <!-- CARD APROVAÇÃO-->
        <b-col xl="3" sm="6" class="p-2">
          <b-media no-body class="panel" @click="setGeralFilter(2)">
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="info">
                {{ calcTotalPercent(2) }}%
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">{{ setGeral(2) }}</h4>
              <b-card-text class="font-small-3 mb-0"> AUSA </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <!-- CARD CREDENCIADOS-->
        <b-col xl="3" sm="6" class="p-2">
          <b-media no-body class="panel" @click="setGeralFilter(3)">
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="primary">
                {{ calcTotalPercent(3) }}%
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">{{ setGeral(3) }}</h4>
              <b-card-text class="font-small-3 mb-0">
                CREDENCIADOS
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import { baseApiUrl } from "@/global";
export default {
  data() {
    return {
      cadastros: [],
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    async getCadastros(filter = "") {
      await axios
        .post(`${baseApiUrl}/cadastros`, {
          db: this.user.user.produto.db,
          filter,
        })
        .then((res) => {
          if (res.data.success) {
            this.cadastros = res.data.data;
          }
        });
    },
    calcTotalPercent(status) {
      let total = this.cadastros.length;
      let count = 0;
      this.cadastros.filter((cadastro) => {
        if (cadastro.status.id === status) {
          count++;
        }
      });
      if (count === 0) return count;
      return (count * 100) / total;
    },
    setPanel(stats) {
      let count = 0;
      this.cadastros.filter((cadastro) => {
        if (cadastro.status.name === stats) {
          count++;
        }
      });
      return count;
    },
    setGeralFilter(filtro) {
      let lista = this.cadastros.filter((cadastro) => {
        return cadastro.status.id === filtro;
      });
      this.$emit("setList", lista);
    },
    setGeral(status) {
      let count = 0;
      this.cadastros.filter((cadastro) => {
        if (cadastro.status.id === status) {
          count++;
        }
      });
      return count;
    },
  },
  mounted() {
    this.getCadastros();
  },
};
</script>

<style>
</style>