<template>
  <div>
    <Cadastros />
  </div>
</template>

<script>
import Cadastros from './components/Cadastros.vue'
export default {
  components: {
    Cadastros
  }

}
</script>

<style>

</style>